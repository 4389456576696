import React from 'react';
import { NextPage } from 'next';

const Index: NextPage = () => (
  <div style={{ width: 300 }}>
    ⣿⠟⠛⠛⠋⠋⠛⠛⢻⣿⣿⡿⠟⠉⢽⣿⣿⣿⣿⠿⠛⠋⠉⣿⣿⡿⠟⠛⠉⢹ ⣷⣾⠋⢀⣿⣿⠗⢀⣴⣿⣿⠃⢀⣄⠀⢸⣿⡟⠁⣠⣤⣶⣿⣿⠋⢀⣦⣴⣾⣿
    ⣿⠟⠀⢈⣩⣴⣾⣿⣿⣿⠃⢠⣿⣿⠀⢸⣿⣧⡀⠈⠻⣿⣿⣿⣄⠀⠹⢿⣿⣿ ⣿⡆⠀⣤⣉⣙⠛⠿⡟⠁⢀⣈⣤⣤⠀⢰⣿⣿⣿⣦⡀⠈⢻⣿⣿⣷⣄⠀⠙⣿
    ⣿⡇⠀⠿⠛⠋⣀⣠⡏⢀⣿⣿⣿⣿⠀⣼⣿⠿⠟⠋⢁⣠⣬⡿⠟⠛⠉⣀⣤⣽ ⣷⣦⣴⣶⣾⣿⣿⣿⣤⣾⣿⣿⣿⣿⣶⣿⣿⣶⣶⣾⣿⣿⣿⣷⣶⣶⣿⣿⣿⣿
    ⣿⡟⢛⣿⠛⣻⣿⠏⣿⣿⣿⠛⣿⡟⢻⡟⢛⣛⠻⣿⠛⣿⣿⠏⣹⠛⣛⣛⠻⣿ ⣿⡇⣼⡏⣠⡘⠿⢰⣿⣿⣿⣆⢉⣴⡏⢰⣿⡿⣃⣿⠀⣿⠟⢠⣿⠀⠩⣿⣾⣿
    ⣿⣷⣿⣵⣿⣿⣶⣿⣿⣿⣿⣧⣾⣿⣿⣶⣷⣾⣿⣿⣷⣶⣷⣾⣿⣾⣿⣶⣬⣿ ⣿⣿⡿⠛⠛⠛⢻⣿⣿⣿⠟⠋⣽⣿⣿⣿⣿⣿⠿⠛⠛⠻⣿⣿⣿⠿⠛⠋⠉⢻
    ⣿⡏⠀⣴⣶⣶⣾⣿⣿⠇⢀⣀⠀⢹⣿⣿⠟⢡⣶⣾⡗⠀⣿⣿⠁⢰⣿⣿⣿⣿ ⣿⠃⠠⠿⠛⠛⢻⣿⡏⢀⣾⣿⠀⢸⣿⠃⢠⣿⣿⣿⠀⢠⣿⡇⠀⠟⠛⠛⠛⣿
    ⣧⠀⢰⣶⣶⣶⣾⠋⠀⣈⣉⣥⠀⢸⣿⠀⢸⣿⣿⣿⣶⣿⣿⠃⢰⣶⣿⣿⣿⣿ ⣿⠀⣿⣿⣿⣿⣿⠂⣼⣿⣿⣿⠀⣿⣿⡄⠀⠻⠿⠿⠟⣋⣿⠀⠸⠿⠛⠛⣉⣽
    ⣿⣀⣿⣿⣿⣿⣯⣼⣿⣿⣿⣿⣴⣿⣿⣿⣷⣶⣶⣶⣿⣿⣿⣷⣶⣶⣾⣿⣿⣿
  </div>
);

export default Index;
